<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('DepartmentCreate')"
    @edit="(id) => onEdit('DepartmentEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'Departments',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Departments',
      tableCaption: 'Подразделения',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Подразделения',
        },
      ],
      tableHeaders: [
        { text: 'Наименование', alias: 'name', order: 'name' },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
